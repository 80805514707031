<template>
  <router-view :key="$route.fullPath"></router-view>
</template>
<script>
export default {
  data() {
    return {
      list: [{
        path: '',
        name: '财务管理'
      }]
    }
  },
}
</script>
<style lang='scss'>
</style>
